export const BackgroundEffect = true;
export const SideMenuDisabled = false;
export const MenuGestureThreshold = 85;
export const ObserverRootMargin = "-75px";
export const PlaceholderImg = "../assets/img/placeholder.jpg";

export const customIcons = {
  logoTypeScript: "../assets/svg/icons8-typescript.svg",
  logoPhp: "../assets/svg/icons8-php-logo.svg",
  logoMysql: "../assets/svg/icons8-mysql-logo.svg",
  logoIonic: "../assets/svg/icons8-ionic.svg",
  logoIonicColor: "../assets/svg/icons8-ionic-color.svg",
  logoExpress: "../assets/svg/expressjs-icon.svg",
  logoCpp: "../assets/svg/icons8-c++.svg",
  logoGo: "../assets/svg/go-logo.svg",
  logoGoMascot: "../assets/svg/icons8-golang.svg",
  logoFlutter: "../assets/svg/icons8-flutter.svg",
  logoReact: "../assets/svg/icons8-react-color.svg",
  logoSpringBoot: "../assets/svg/spring-boot.svg",
  logoJava: "../assets/svg/java.svg",
  logoCSharp: "../assets/svg/c-sharp.svg",
  logoAzure: "../assets/svg/azure.svg",
  logoAngular: "../assets/svg/icons8-angular-color.svg",
  logoTailwind: "../assets/svg/tailwindcss.svg",
  logoKubernetes: "../assets/svg/kubernetes.svg",
  logoDocker: "../assets/svg/docker.svg",
  logoDotnet: "../assets/svg/dotnet-icon.svg",
  logoDotnetVertical: "../assets/svg/dotnet-vertical.svg",
};
